<template>
  <div class="countNum">
    <div v-if="deptBool" class="serach_box">
      <a-form layout="inline">
        <a-form-item>
          <a-tree-select
            style="width: 360px"
            :treeData="treeData"
            v-model="value"
            treeCheckable
            :showCheckedStrategy="SHOW_ALL"
            placeholder="请选择部门"
            maxTagCount="5"
          />
        </a-form-item>
        <a-form-item>
          <a-range-picker
            style="width:220px;"
            format="YYYY-MM-DD"
            :value="createValue"
            :placeholder="['开始时间', '结束时间']"
            allowClear
            @change="changeTime"
          />
        </a-form-item>

        <a-form-item>
          <a-input
            placeholder="请输入打印机名称"
            style="width:200px;"
            v-model="PrinterName"
          />
        </a-form-item>
        <a-form-item>
          <a-button style="margin-right:20px" @click="resetBtn()"
            >重置</a-button
          >
          <a-button
            style="margin-right:20px"
            type="primary"
            @click="serachBtn()"
            >查询</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div id="C1"></div>
    <div v-if="!deptBool" class="serach_box">
      <a-form layout="inline">
        <a-form-item>
          <a-input
            placeholder="请输入账号"
            style="width:200px;"
            v-model="UserAccount"
          />
        </a-form-item>
        <a-form-item>
          <a-input
            placeholder="请输入打印机名称"
            style="width:200px;"
            v-model="PrinterName"
          />
        </a-form-item>
        <a-form-item>
          <a-input
            placeholder="请输入姓名"
            style="width:200px;"
            v-model="UserName"
          />
        </a-form-item>
        <a-form-item>
          <a-tree-select
            style="min-width: 220px;"
            :treeData="department_arr"
            :value="departmentName ? departmentName : undefined"
            @change="onChangeTree"
            treeNodeLabelProp="title"
            :showCheckedStrategy="SHOW_PARENT"
            placeholder="请选择部门"
          />
        </a-form-item>
        <a-form-item>
          <a-range-picker
            style="width:220px;"
            format="YYYY-MM-DD"
            :value="createValue"
            :placeholder="['开始时间', '结束时间']"
            allowClear
            @change="changeTime"
          />
        </a-form-item>
        <a-form-item>
          <a-button style="margin-right:20px" @click="resetBtn()"
            >重置</a-button
          >
          <a-button
            style="margin-right:20px"
            type="primary"
            @click="serachBtn()"
            >查询</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <a-table
      v-if="deptBool"
      :columns="columns_dept"
      :dataSource="dataList"
      bordered
      :pagination="false"
      size="small"
      :customRow="rowClick"
      style="margin-top:10px;border:1px solid #efefef"
    />
    <a-table
      v-else
      :columns="columns"
      :dataSource="dataList"
      bordered
      :pagination="false"
      size="small"
      :customRow="rowClick"
      :scroll="{ x: '100%' }"
      style="margin-top:10px;border:1px solid #efefef"
    />
    <p style="margin: 10px 0px;font-size:12px;">
      <span style="color:red;">*</span> 黑白5分/张 , 彩色5角/张
    </p>
    <div style="display:flex;justify-content: space-between;">
      <div>
        每页<a-input
          type="number"
          placeholder="8"
          ref="pages"
          :step="5"
          :min="10"
          :max="9999"
          size="small"
          style="width:68px;"
        />条
        <a-button type="primary" size="small" @click="changeSize"
          >确定</a-button
        >
      </div>
      <a-pagination
        showQuickJumper
        :defaultCurrent="1"
        :total="total"
        :pageSize="Number(pagesize)"
        @change="pageChange"
      />
    </div>
    <a-spin style="position:fixed;left:50%;top:150px" v-if="spinning">
      <a-icon
        slot="indicator"
        type="loading"
        :spinning="spinning"
        style="font-size: 44px;color:red;"
        spin
      />
    </a-spin>
  </div>
</template>
<script>
import G2 from '@antv/g2'
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
const SHOW_ALL = TreeSelect.SHOW_ALL
const columns_dept = [
  // {
  //   title: '名字',
  //   dataIndex: 'UserName',
  //   key: 'UserName',
  //   width: 200,
  // },
  {
    title: '部门名称',
    dataIndex: 'DepartmentName',
    key: 'DepartmentName',
    width: 200
  },
  {
    title: '总费用(元)',
    dataIndex: 'TotalCost',
    key: 'TotalCost',
    width: 200
  },
  {
    title: '复印',
    children: [
      {
        title: '黑白',
        dataIndex: 'copy_blackwhite',
        key: 'copy_blackwhite',
        width: 150
      },
      {
        title: '彩色',
        dataIndex: 'copy_colorful',
        key: 'copy_colorful',
        width: 150
      },
      {
        title: '单面',
        dataIndex: 'copy_single',
        key: 'copy_single',
        width: 150
      },
      {
        title: '双面',
        dataIndex: 'copy_double',
        key: 'copy_double',
        width: 150
      },
      {
        title: '总量',
        dataIndex: 'copy_total',
        key: 'copy_total',
        width: 150
      }
    ]
  },
  {
    title: '打印',
    children: [
      {
        title: '黑白',
        dataIndex: 'print_blackwhite',
        key: 'print_blackwhite',
        width: 150
      },
      {
        title: '彩色',
        dataIndex: 'print_colorful',
        key: 'print_colorful',
        width: 150
      },
      {
        title: '单面',
        dataIndex: 'print_single',
        key: 'print_single',
        width: 150
      },
      {
        title: '双面',
        dataIndex: 'print_double',
        key: 'print_double',
        width: 150
      },
      {
        title: '总量',
        dataIndex: 'print_total',
        key: 'print_total',
        width: 150
      }
    ]
  },
  {
    title: '扫描',
    dataIndex: 'scan',
    key: 'scan',
    width: 160
  }
]
const columns = [
  {
    title: '名字',
    dataIndex: 'UserName',
    key: 'UserName',
    width: 200
  },
  {
    title: '部门',
    dataIndex: 'DepartmentName',
    key: 'DepartmentName',
    width: 200
  },
  {
    title: '总费用',
    dataIndex: 'TotalCost',
    key: 'TotalCost',
    width: 200
  },
  {
    title: '复印',
    children: [
      {
        title: '黑白',
        dataIndex: 'copy_blackwhite',
        key: 'copy_blackwhite',
        width: 150
      },
      {
        title: '彩色',
        dataIndex: 'copy_colorful',
        key: 'copy_colorful',
        width: 150
      },
      {
        title: '单面',
        dataIndex: 'copy_single',
        key: 'copy_single',
        width: 150
      },
      {
        title: '双面',
        dataIndex: 'copy_double',
        key: 'copy_double',
        width: 150
      },
      {
        title: '总量',
        dataIndex: 'copy_total',
        key: 'copy_total',
        width: 150
      }
    ]
  },
  {
    title: '打印',
    children: [
      {
        title: '黑白',
        dataIndex: 'print_blackwhite',
        key: 'print_blackwhite',
        width: 150
      },
      {
        title: '彩色',
        dataIndex: 'print_colorful',
        key: 'print_colorful',
        width: 150
      },
      {
        title: '单面',
        dataIndex: 'print_single',
        key: 'print_single',
        width: 150
      },
      {
        title: '双面',
        dataIndex: 'print_double',
        key: 'print_double',
        width: 150
      },
      {
        title: '总量',
        dataIndex: 'print_total',
        key: 'print_total',
        width: 150
      }
    ]
  },
  {
    title: '扫描',
    dataIndex: 'scan',
    key: 'scan',
    width: 160
  }
]
import moment from 'moment'
export default {
  name: 'countNum',
  data () {
    return {
      SHOW_PARENT,
      SHOW_ALL,
      dataList: [],
      dataCount: [], //统计图数据
      columns,
      pagesize: 8, //每页8条
      index: 1, //页码
      total: 1, //总数

      UserAccount: '',
      UserName: '',
      spinning: false,

      deptBool: false, //区分部门统计还是。。。
      treeData: [],
      columns_dept,
      value: [],
      department_arr: [],
      departmentName: '',
      createValue: [],
      startDate: '',
      endDate: '',
      PrinterName: ''
    }
  },
  mounted () {
    let that = this
    if (this.$route.query.type == 'dept_count') {
      this.deptBool = true
      // that.$axios.get(8026,{},res=>{
      //     console.log(res.data);
      //     if(res.data){return false;}
      //     if (res.data.code==1) {
      //         that.treeData = res.data.data;
      //     }else {
      //         that.$message.error(res.data.msg);
      //     }
      // })
      if (sessionStorage.getItem('department_arr')) {
        this.treeData = JSON.parse(sessionStorage.getItem('department_arr'))
      } else {
        this.$axios.get(8012, {}, res => {
          //改变权限设置方发
          if (!res.data) {
            return false
          }
          if (res.data.code == 1) {
            this.treeData = res.data.data
            sessionStorage.setItem(
              'department_arr',
              JSON.stringify(res.data.data)
            )
          }
        })
      }
    } else {
      this.deptBool = false
    }
    if (sessionStorage.getItem('department_arr')) {
      this.department_arr = JSON.parse(sessionStorage.getItem('department_arr'))
    } else {
      this.$axios.get(8012, {}, res => {
        //改变权限设置方发
        if (!res.data) {
          return false
        }
        if (res.data.code == 1) {
          this.department_arr = res.data.data
          sessionStorage.setItem(
            'department_arr',
            JSON.stringify(res.data.data)
          )
        }
      })
    }
    this.tableFn()
  },
  methods: {
    moment,
    tableFn () {
      let that = this
      that.spinning = true
      if (that.deptBool) {
        that.$axios.post(
          8025,
          {
            DepartmentId: that.value.join(','),
            PageSize: that.pagesize,
            Page: that.index,
            StartTime: that.startDate ? that.startDate : '',
            EndTime: that.endDate ? that.endDate : '',
            PrinterName: that.PrinterName ? that.PrinterName : ''
          },
          res => {
            // console.log(res.data);
            that.spinning = false
            if (!res.data) {
              return false
            }
            if (res.data.code == 1) {
              that.dataCount = []
              that.dataList = res.data.data
              that.total = res.data.count
              for (let i = 0; i < res.data.top.length; i++) {
                that.dataCount.push({
                  UserName: res.data.top[i].UserName,
                  company: '复印',
                  value: res.data.top[i].copy
                })
                that.dataCount.push({
                  UserName: res.data.top[i].UserName,
                  company: '打印',
                  value: res.data.top[i].print
                })
              }
              //  console.log(that.dataCount)
              that.init()
            } else {
              that.$message.error(res.data.msg)
            }
          }
        )
      } else {
        that.$axios.post(
          8024,
          {
            UserAccount: that.UserAccount,
            UserName: that.UserName,
            PrinterName: that.PrinterName ? that.PrinterName : '',
            PageSize: that.pagesize,
            Page: that.index,
            DepartmentId: that.departmentName,
            StartTime: that.startDate ? that.startDate : '',
            EndTime: that.endDate ? that.endDate : ''
          },
          res => {
            // console.log(res.data);
            that.spinning = false
            if (res.data.code == 1) {
              that.dataCount = []
              that.dataList = res.data.data
              that.total = res.data.count
              for (let i = 0; i < res.data.top.length; i++) {
                that.dataCount.push({
                  UserName: res.data.top[i].UserName,
                  company: '复印',
                  value: res.data.top[i].copy
                })
                that.dataCount.push({
                  UserName: res.data.top[i].UserName,
                  company: '打印',
                  value: res.data.top[i].print
                })
              }
              //  console.log(that.dataCount)
              that.init()
            } else {
              that.$message.error(res.data.msg)
            }
          }
        )
      }
    },
    onChangeTree (value) {
      console.log(value)
      this.departmentName = value
    },
    onChange (value) {
      console.log('cx', value)
    },
    init () {
      let that = this
      document.getElementById('C1').innerHTML = ''
      let chart = new G2.Chart({
        container: 'C1',
        forceFit: true,
        height: (window.innerHeight * 1) / 3,
        padding: 'auto'
      })
      chart.source(that.dataCount)
      chart.scale('value', {
        alias: '数量',
        tickCount: 10
      })
      chart.axis('UserName', {
        label: {
          textStyle: {
            fill: '#aaaaaa'
          }
        },
        tickLine: {
          alignWithLabel: false,
          length: 0
        }
      })

      chart.axis('value', {
        label: {
          textStyle: {
            fill: '#aaaaaa'
          }
        },
        title: {
          offset: 50
        },
        line: {
          lineWidth: 2, // 设置线的宽度
          stroke: '#cfcfcf' // 设置线的颜色
          // lineDash: [ 3, 3 ] // 设置虚线样式
        }
      })
      chart.legend({
        position: 'bottom' // 设置图例的显示位置
      })
      chart
        .interval()
        .position('UserName*value')
        .color('company')
        .opacity(1)
        .adjust([
          {
            type: 'dodge',
            marginRatio: 1 / 32
          }
        ])
        .label('value')
      chart.render()
    },
    //分页
    pageChange (pageNumber) {
      console.log(pageNumber)
      this.index = pageNumber
      this.tableFn()
    },
    //搜索时间
    changeTime (c, value) {
      this.createValue = c
      this.startDate = value[0]
      this.endDate = value[1]
      let date1 = new Date(this.startDate)
      this.startDate =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 < 10
          ? '0' + (date1.getMonth() + 1)
          : date1.getMonth() + 1) +
        '-' +
        (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
      let date2 = new Date(this.endDate)
      this.endDate =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 < 10
          ? '0' + (date2.getMonth() + 1)
          : date2.getMonth() + 1) +
        '-' +
        (date2.getDate() < 10 ? '0' + date2.getDate() : date2.getDate())
      // console.log(this.startDate,this.endDate)
    },
    //重置
    resetBtn () {
      this.UserAccount = ''
      this.UserName = ''
      this.startDate = ''
      this.endDate = ''
      this.PrinterName = ''
      this.index = 1
      this.value = []
      this.createValue = []
      this.tableFn()
    },
    changeSize () {
      console.log(this.$refs.pages.stateValue)
      if (
        this.$refs.pages.stateValue < 10 ||
        this.pagesize == this.$refs.pages.stateValue
      ) {
        this.pagesize = 10
      } else {
        this.pagesize = this.$refs.pages.stateValue
      }
      this.tableFn()
    },
    //搜索按钮
    serachBtn () {
      let that = this
      console.log(that.value)
      if (
        that.DepartmentName ||
        that.departmentName ||
        that.UserAccount ||
        that.UserName ||
        that.startDate ||
        that.endDate ||
        that.value ||
        that.PrinterName
      ) {
        that.index = 1
        that.tableFn()
      } else {
        that.$message.error('都为空不请求!')
        return false
      }
    },
    // 点击table
    rowClick: (record, index) => {
      return {
        on: {
          click: () => {
            console.log(record, index)
          }
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.countNum {
  padding: 16px;
  background: #fff;

  .serach_box {
    position: relative;
    .map-position {
      position: absolute;
      top: 4px;
      right: 0px;
    }
    .item-label {
      margin-right: 12px;
      margin-bottom: 12px;
      width: 160px;
    }
  }
}
</style>
